@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fafc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar::-webkit-scrollbar {
  width: 20px;
  height: 14px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #303030;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #5f5f5f;
  border-radius: 100vh;
  border: 3px solid #303030;
}
